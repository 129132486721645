import { MainWrapper } from './mainStyle.js';

function Main({ isModified }) {
    const googleMapsLink = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6052.345118645967!2d16.6003656!3d40.6701667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13477ee1ba64bf89%3A0xbf221c87b58bda66!2sOrtofrutta%20da%20Germano%20e%20Monia!5e0!3m2!1sit!2sit!4v1691441453568!5m2!1sit!2sit";
    return (
        <MainWrapper className={`py-5 main ${isModified ? 'bg-dark' : ''} ${isModified ? 'text-light' : ''}`} >
            <div className='container py-5'>
                <iframe
                    title="Mappa"
                    src={googleMapsLink}
                    className="map"
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                />
            </div>
        </MainWrapper>
    );
}

export default Main;