import styled from 'styled-components';

export const SliderWrapper = styled.div`

.carousel-indicators button{
    width: 11px;
    height: 11px;
    margin-right: 3px;
    margin-left: 3px;
    background-color: #EBECF4;
    border-top: unset;
    border-bottom: unset;
    border-radius: 50px;
    transition: all 0.5s ease;
}

.carousel-control-next, .carousel-control-prev {
    width: 7%;
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    width: 25px;
    height: 25px;
}

.carousel-indicators .active{
    width: 35px;
    transition: all 0.5s ease;
}

.carousel-caption {
    display: flex;
    justify-content: start;
    align-items: center;
    /* Altre proprietà CSS desiderate */
}

.carousel-caption .text-start {
    text-align: left;
    /* Altre proprietà CSS desiderate */
}

.slider-image {
    max-height: 100vh;
    object-fit: cover;
}

@media (max-width: 992px) {
    .slider-image {
        height: 50vh; 
        object-fit: center;
    }
}
`;