import styled from 'styled-components';

export const MainWrapper = styled.main`

main{
    min-height: 100vh;
}

.container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.map{
    width: 100%;
    height: 400px;
}
`;