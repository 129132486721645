import { SliderWrapper } from './sliderStyle.js';

function Main({ isModified }) {
    return (
        <SliderWrapper className={`${isModified ? 'text-light' : ''}`} >
            <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval={4000}>
                        <img src={"slide/sfondo0.jpg"} className="d-block w-100 slider-image" alt="..." />
                    </div>
                    <div className="carousel-item active" data-bs-interval={4000}>
                        <img src={"slide/sfondo1.jpg"} className="d-block w-100 slider-image" alt="..." />
                    </div>
                    <div className="carousel-item active" data-bs-interval={4000}>
                        <img src={"slide/sfondo2.jpg"} className="d-block w-100 slider-image" alt="..." />
                    </div>
                </div>
            </div>
        </SliderWrapper>
    );
}

export default Main;