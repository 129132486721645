import styled from 'styled-components';

export const FooterWrapper = styled.footer`

.py-4{
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.form-check {
    position: relative;
    width: 50px; /* Larghezza del toggle */
    height: 25px; /* Altezza del toggle */
    background-color: #ccc; /* Colore di sfondo del toggle spento */
    border-radius: 12.5px; /* Per ottenere un aspetto circolare */
    cursor: pointer;
  }
  
  .form-check-label {
    position: absolute;
    top: 50%;
    left: 8px; /* Aggiusta questo valore per allineare correttamente l'icona */
    transform: translateY(-50%);
    color: #fff; /* Cambia il colore dell'icona a tuo piacimento */
    pointer-events: none; /* Per evitare l'interazione con il testo dell'icona */
  }
  
  .form-check-input {
    /* Nascondi il pallino dello switch predefinito */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
  }
  
  /* Aggiungi uno stile personalizzato per la parte "accesa" del toggle */
  .form-check.active {
    background-color: #007bff; /* Cambia il colore di sfondo del toggle acceso */
  }
  
  .form-check.active .form-check-label {
    left: 24px; /* Aggiusta questo valore per allineare correttamente l'icona quando il toggle è acceso */
  }

  .form-check {
    /* ... */
    transition: background-color 0.2s, box-shadow 0.2s;
  }
  
  .form-check-label {
    /* ... */
    transition: left 0.2s;
  }
  
  .form-check.active {
    /* ... */
    background-color: #007bff; /* Cambia il colore di sfondo del toggle acceso */
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5); /* Effetto di ombreggiatura per evidenziare il toggle acceso */
  }
`;