import React, { useState } from 'react';
//import logo from './logo.svg';
import './App.css';
import Header from './Home/header/header.js';
import Footer from './Home/footer/footer.js';
import Main from './Home/content/main.js';
import Slider from './Home/slider/slider.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  const [isModified, setIsModified] = useState(false);

  const handleClick = () => {
    setIsModified(!isModified);
  };

  return (
    <>
      <div className="page-wrapper">
        <div>
          <div className="header-container">
            <Header isModified={isModified} />
          </div>
          <div className="slider-container">
            <Slider isModified={isModified} />
          </div>
        </div>
        <div className="content-container">
          <Main isModified={isModified} />
          <Footer isModified={isModified} handleClick={handleClick} />
        </div>
      </div>
    </>
  );
}

export default App;
