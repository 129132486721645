import styled from 'styled-components';

export const HeaderWrapper = styled.nav`
nav{
    --bs-navbar-padding-x: 1.5rem !important;
    --bs-navbar-nav-link-padding-x: 1.25rem !important;
}

.navbar-nav {
    --bs-nav-link-font-size: 1.25rem;
}

.navbar{
    --bs-navbar-brand-font-size: 1.5rem; 
}

.nav-link{
    transition: all 0.6s ease;
    position: relative;
  }


.nav-link::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    transition: all 0.3s ease;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

.light.nav-link::after{
  background-color: black;
  }

.dark.nav-link::after{
  background-color: white;
  }

  
.nav-link:hover::after {
    width: 100%;
  }

@media only screen and (max-width: 991px) {
    .nav-link::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 0;
        background-color: black;
        transition: all 0.3s ease;
        bottom: 0;
        left: 0%;
        transform: translateX(0%);
      }
      
    .nav-link:hover::after {
        width: 23.5%;
      }
  }
`;